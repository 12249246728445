import React from 'react';
import { useLocation } from '@reach/router';
import '../styles/global.css';
import Header from './header';
import Footer from './footer';
import BackToTop from './BackToTop';
// import KeyDates from './KeyDates';

export default function Layout({ children }) {
  const location = useLocation();

  const customPages = ["/", "/destination/", "/venue/"];
  const shouldHaveClasses = customPages.includes(location.pathname);

  return (
    <>
      <Header />
      <main className={`relative bg-white ${!shouldHaveClasses ? 'flex flex-col gap-16 pb-12 md:pb-16 lg:pb-20' : ''}`}>
        {children}
      </main>
      <Footer />
      <BackToTop />
      {/* <KeyDates /> */}
    </>
  );
}
